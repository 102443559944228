import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Layout, PaginationSimple, BooksAuthor } from "../components"
// import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import slugifyPost from "../../slugifyPost"

const SingleAuthorPage = ({ data, pageContext }) => {
  const { authorFullName } = data.contentfulAuthor

  // console.log("fullname, created", authorFullName, createdAt)

  // console.log("autor data", data)
  const { previous, next } = pageContext

  // console.log("previous", previous)

  // const {
  //   contentfulAuthor: { book: books },
  // } = data

  const books = data.contentfulAuthor.book
    ? data.contentfulAuthor.book
    : data.contentfulAuthor.magazine

  const category = data.contentfulAuthor.book ? "book" : "magazine"

  // console.log("authors", authors)

  const prevTitle = previous ? previous.authorFullName : null
  const nextTitle = next ? next.authorFullName : null
  const prevRow = previous ? previous.createdAt : null
  const nextRow = next ? next.createdAt : null
  const allItemPage = "/translation/authors/1"
  const oneItemPage = "/translation/author/"

  return (
    <Wrapper>
      <Layout>
        <div className="section-center">
          <h1>{authorFullName}</h1>
          <BooksAuthor
            title="Összes fordítás a szerzőtől"
            books={books}
            page
            category={category}
          />
        </div>
        <PaginationSimple
          oneItemPage={oneItemPage}
          allItemPage={allItemPage}
          prevTitle={prevTitle}
          nextTitle={nextTitle}
          prevRow={prevRow}
          nextRow={nextRow}
          pageContext={pageContext}
        />
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  min-height: 100vh;
  background: var(--clr-light-5);
  padding-top: 9rem;
  padding-bottom: 0rem;
  color: var(--clr-primary-4);

  nav {
    background-color: rgba(0, 0, 0, 0.85);
  }

  .section-center {
    h1 {
      text-align: center;
    }
    h2 {
      text-align: center;
      padding-bottom: 0rem;
    }

    h3 {
      ${"" /* text-align: center; */}
      padding-bottom: 1rem;
      padding-top: 1rem;
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: 500;
      text-align: center;
    }

    h4 {
      text-align: center;
      ${"" /* text-transform: uppercase; */}
    }

    .publisher {
      font-weight: bold;
    }

    .date-page {
      text-transform: lowercase;
      padding-bottom: 2rem;
      font-weight: bold;
    }

    .info {
      padding-left: 10%;
      padding-bottom: 2rem;
      color: var(--clr-grey-1);
      border-left: 2px solid var(--clr-primary-5);
    }
  }

  .img {
    border-radius: var(--radius);
    transition: var(--transition);
  }

  a {
    ${"" /* display: block; */}
    width: auto;
    text-align: center;
    ${"" /* padding: 1rem 1rem; */}
    margin: 0 auto;
    ${"" /* margin-top: 1rem; */}
    color: var(--clr-light-3);
  }

  .btn {
    color: var(--clr-primary-22);
  }

  .btn:hover {
    color: var(--clr-primary-1);
  }

  @media (min-width: 768px) {
    .section-center {
      width: 80%;
    }
  }
  @media (min-width: 992px) {
    .section-center {
      width: 70%;
    }
  }
  @media (min-width: 1200px) {
    .section-center {
      width: 60%;
    }
  }
`

export const query = graphql`
  query getTranslationAuthor($recordId: String!) {
    contentfulAuthor(contentful_id: { eq: $recordId }) {
      contentful_id
      createdAt(formatString: "YYYY-MM-DD")
      authorFullName
      authorFirstName
      authorMiddleName
      authorLastName
      book {
        contentful_id
        bookTitle
        author {
          authorFullName
        }
        publisher {
          publisherName
        }
        bookYear {
          bookYear
        }
        bookSize
        bookText {
          childMarkdownRemark {
            excerpt(format: HTML, pruneLength: 100)
          }
        }
        bookImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
        }
      }

      magazine {
        contentful_id
        bookTitle: textTitle
        author {
          authorFullName
        }
        publisher {
          publisherName
        }
        bookPublished: magazinePublished(formatString: "YYYY-MM-DD")
        bookSize: textSize
        bookImage: magazineImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
        }
      }
    }
  }
`

export default SingleAuthorPage
